import OrderShippingEstimations from '../OrderShippingEstimations.vue';

import { createOrderPackage, createOrderPackageItem } from '../../_meta/order';

const orderItemsHeaders = Object.freeze([
  { text: 'Order Item ID', value: 'orderItemId', sortable: false },
  { text: 'Product Type', value: 'productType', sortable: false },
  {
    text: 'Quantity', value: 'quantity', sortable: false, width: '300px',
  },
]);

export default {
  name: 'OrderForm',

  props: {
    order: {
      type: Object,
      required: true,
    },
    orderCreator: {
      type: Function,
      required: true,
    },
    orderPackageCreator: {
      type: Function,
      default: createOrderPackage,
    },
    addressLabel: {
      type: String,
      default: 'From',
    },
    addressKey: {
      type: String,
      default: 'from',
    },
    needOrderItems: {
      type: Boolean,
      default: true,
    },
    needPackageItems: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    OrderShippingEstimations,
  },

  data() {
    return {
      orderForm: this.orderCreator(),
      selectedOrderItems: [],

      orderItemsHeaders,
    };
  },

  computed: {
    orderStr() {
      return JSON.stringify(this.order);
    },

    disableSubmitBtn() {
      const invalidOrderItemQuantity = this.needOrderItems
        ? this.orderForm.orderItems
          .some(({ orderItemId }) => this.validateOrderItemQuantity(orderItemId).length)
        : false;
      const invalidSelectedItemsCount = this.needOrderItems
        ? this.selectedOrderItems.length < 1
        : false;
      const invalidOrderPackages = this.orderForm.packages.some(({ weight }) => weight <= 0);

      return invalidOrderItemQuantity
        || invalidSelectedItemsCount
        || invalidOrderPackages
        || !this.orderForm.shipMethod;
    },
  },

  watch: {
    orderStr: {
      handler(newValue) {
        try {
          Object.assign(this.orderForm, JSON.parse(newValue));
        } catch (e) {
          this.orderForm = {};
        }
      },
      immediate: true,
    },
  },

  methods: {
    createOrderPackage() {
      this.orderForm.packages.push(this.orderPackageCreator());
    },

    createOrderPackageItem(pack) {
      if (!pack.packageItems) {
        this.$set(pack, 'packageItems', []);
      }

      pack.packageItems.push(createOrderPackageItem());
    },

    removeOrderPackage(pack) {
      this.orderForm.packages = this.orderForm.packages.filter(item => item !== pack);
    },

    removeOrderPackageItem(pack, packageItem) {
      pack.packageItems = pack.packageItems.filter(item => item !== packageItem);
    },

    findOrderItem(orderItemId) {
      return this.orderForm.orderItems.find(({ orderItemId: id }) => id === orderItemId);
    },

    setOriginalOrderItemQuantityValue(item) {
      const originalOrderItem = this.order.orderItems
        .find(({ orderItemId }) => orderItemId === item.orderItemId);

      item.quantity = originalOrderItem.quantity;
    },

    selectItem({ item, value }) {
      if (value) {
        this.selectedOrderItems.push(item);
      } else {
        this.selectedOrderItems.splice(this.selectedOrderItems.indexOf(item), 1);

        const orderItem = this.orderForm.orderItems
          .find(({ orderItemId: id }) => id === item.orderItemId);

        this.setOriginalOrderItemQuantityValue(orderItem);
      }
    },

    selectAllItems({ value }) {
      if (value) {
        this.orderForm.orderItems.forEach((item) => {
          if (!this.selectedOrderItems.includes(item)) {
            this.selectedOrderItems.push(item);
          }
        });
      } else {
        this.selectedOrderItems = [];

        this.orderForm.orderItems.forEach((item) => {
          this.setOriginalOrderItemQuantityValue(item);
        });
      }
    },

    validateOrderItemQuantity(orderItemId) {
      const { quantity } = this.orderForm.orderItems
        .find(({ orderItemId: id }) => id === orderItemId);

      if (!`${quantity}`.length) {
        return ['Required field'];
      }
      if (quantity < 1) {
        return ['Can\'t be less 1'];
      }

      const { quantity: originalOrderItemQuantity } = this.order.orderItems
        .find(({ orderItemId: id }) => id === orderItemId);

      return quantity > originalOrderItemQuantity
        ? [`Can't be more ${originalOrderItemQuantity}`]
        : [];
    },

    submitForm() {
      this.$emit('submit', {
        order: this.orderForm,
        selectedItems: this.selectedOrderItems,
      });
    },

    changeOrderShipping({ shipMethod, shipService }) {
      this.orderForm.shipMethod = shipMethod;
      this.orderForm.shipService = shipService;
    },

    resetForm() {
      this.$emit('reset');
      this.selectedOrderItems = [];
    },
  },
};
