import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const ShippingApiService = {
  async estimateShipping({ orderId, order, mode }) {
    try {
      const { data } = await HTTP.post(`operations/${orderId}/estimate`, order, {
        params: {
          mode,
        },
      });

      return Promise.resolve(data || {});
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
