<template>
  <v-layout column>
    <v-layout
      v-for="label in labels"
      :key="label.id"
      align-center
      class="mt-5"
      column
    >
      <v-layout
        align-center
        column
      >
        <v-btn
          v-if="label.tradeDocumentData"
          color="success"
          width="150px"
          class="mb-2"
          x-small
          @click="downloadTradeDocument(label)"
        >
          Trade Document
          <v-icon
            class="ml-1"
            x-small
          >
            file_download
          </v-icon>
        </v-btn>
        <strong
          v-if="label.deliveryDate"
          class="mb-2"
        >
          Delivery Date: {{ formatDisplayDate(label.deliveryDate) }}
        </strong>
        <strong
          v-if="label.trackingNumber"
          class="mb-2"
        >
          Tracking Number: {{ label.trackingNumber }}
        </strong>
      </v-layout>

      <iframe
        :src="`data:application/pdf;base64,${label.data}`"
        width="500px"
        height="700px"
      />
    </v-layout>
  </v-layout>
</template>

<script>
import { formatDisplayDate } from '@helpers';

export default {
  name: 'ShippingLabelsViewer',

  props: {
    labels: {
      type: Array,
      required: true,
    },
  },

  methods: {
    downloadTradeDocument({ tradeDocumentData, shipperType, serviceCode }) {
      const src = `data:application/pdf;base64,${tradeDocumentData}`;
      const linkElem = document.createElement('a');
      const fileName = `${shipperType}-${serviceCode}-trade-document.pdf`;

      linkElem.href = src;
      linkElem.download = fileName;
      linkElem.click();
    },

    formatDisplayDate,
  },
};
</script>
