<template>
  <vbt-dialog
    v-model="dialog"
    title="Shipping rates"
    submit-btn-name="Select"
    @submit="submitEstimation"
    @close="resetForm"
  >
    <template #activator="{ on }">
      <slot
        name="activator"
        :on="on"
      >
        <v-btn
          :loading="loading"
          text
          small
          color="warning"
          @click="estimateShipping"
        >
          Change shipping
        </v-btn>
      </slot>
    </template>

    <vbt-table
      :headers="headers"
      :items="estimation.rates || []"
    >
      <template #actions="{ item }">
        <v-checkbox
          :value="checkSelected(item)"
          @change="setSelectedEstimation(item)"
        />
      </template>

      <template #deliveryDate="{ item: { deliveryDate } }">
        {{ deliveryDate | formatDate('local', '-') }}
      </template>
    </vbt-table>
  </vbt-dialog>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import { ShippingApiService } from '../_services/shipping.api.service';

const headers = Object.freeze([
  { text: '', value: 'actions', width: '10px;' },
  { text: 'Shipper Type', value: 'shipperType' },
  { text: 'Service Name', value: 'serviceName' },
  { text: 'Delivery Date', value: 'deliveryDate' },
  { text: 'Ship Cost', value: 'shipCost' },
]);

export default {
  name: 'OrderShippingEstimations',

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      headers,

      dialog: false,
      loading: false,

      estimation: {},

      selectedEstimation: { name: '', shipMethod: '', shipService: '' },
    };
  },

  computed: {
    orderId() {
      return this.order.printProviderOrderId || '';
    },

    // hack for detecting action mode
    mode() {
      return this.$route.name === 'reship' ? 'reship' : 'return';
    },
  },

  methods: {
    estimateShipping() {
      this.wrapToLoadingFn({
        req: ShippingApiService.estimateShipping.bind({}, {
          orderId: this.orderId,
          order: this.order,
          mode: this.mode,
        }),
        loadingFlagName: 'loading',

        onSuccess: (data) => {
          if (data.rates) {
            data.rates.sort(({ shipCost: a }, { shipCost: b }) => a - b);
          }

          this.estimation = data;
          this.dialog = true;
        },
      });
    },

    setSelectedEstimation(item) {
      this.selectedEstimation = {
        name: `${item.shipperType}-${item.serviceName}-${item.shipCost}`,
        shipMethod: item.shipperType,
        shipService: item.serviceCode,
      };
    },

    checkSelected(item) {
      return this.selectedEstimation.name === `${
        item.shipperType
      }-${
        item.serviceName
      }-${
        item.shipCost
      }`;
    },

    submitEstimation() {
      this.$emit('success', this.selectedEstimation);
      this.dialog = false;
    },

    resetForm() {
      this.estimation = {};
      this.selectedEstimation = { name: '', shipMethod: '', shipService: '' };
    },

    wrapToLoadingFn,
  },
};
</script>
